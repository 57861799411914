export default function () {
  const nuxtApp = useNuxtApp();
  if (process.server) {
    const contextUrl = nuxtApp.ssrContext?.event.node.req.url;
    if (!contextUrl) {
      return null;
    }
    try {
      return new URL(contextUrl).pathname;
    } catch (err) {
      return contextUrl;
    }
  } else {
    return window.location.pathname;
  }
}
